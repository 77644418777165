<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                            <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                <div class="row">
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <ValidationProvider name="Fiscal Year" vid="fiscal_year_id"  rules="required|min_value:1">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="fiscal_year_id"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('warehouse_config.fiscal_year')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="formData.fiscal_year_id"
                                                :options="fiscalList"
                                                id="fiscal_year_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="org_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('org_pro.organization')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="formData.org_id"
                                                :options="orgList"
                                                id="org_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <ValidationProvider name="zone Office Type" vid="zone_office_type_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="zone_office_type_id"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                {{$t('seedsSeeds.office_type')}}<span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                plain
                                                v-model="formData.zone_office_type_id"
                                                :options="zoneOfficeTypeList"
                                                id="zone_office_type_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <ValidationProvider name="office" vid="office_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="office_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('seedsSeeds.office_name')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="formData.office_id"
                                                :options="officeList"
                                                id="office_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                     <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <ValidationProvider name="Season" vid="production_season_id"  rules="required|min_value:1">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="production_season_id"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('seedsConfig.productionSeason')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="formData.production_season_id"
                                                :options="productionSeasonList"
                                                id="production_season_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <ValidationProvider name="Division" vid="division_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="division_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('globalTrans.division')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="formData.division_id"
                                                :options="divisionList"
                                                id="division_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <ValidationProvider name="district" vid="district_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="district_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('globalTrans.district')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="formData.district_id"
                                                :options="districtList"
                                                id="district_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <ValidationProvider name="Upazilla" vid="upazilla_id" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="upazilla_id"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('org_pro_upazilla.upazilla')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="formData.upazilla_id"
                                                :options="upazillaList"
                                                id="upazilla_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                                <div class="invalid-feedback">
                                                {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <ValidationProvider name="Applicant Type" vid="allocation_type" rules="required|min_value:1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="allocation_type"
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('seedsSeeds.disburse_type')}}<span class="text-danger">*</span>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="formData.allocation_type"
                                                    :options="applyByList"
                                                    id="allocation_type"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <ValidationProvider name="Applicant" vid="off_dealer_farmer_comp_id " rules="required|min_value:-1">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="off_dealer_farmer_comp_id "
                                                slot-scope="{ valid, errors }"
                                                >
                                                <template v-slot:label>
                                                    <slot v-if="formData.allocation_type === 1">
                                                        {{ $t('seedsSeeds.institute')}} <span class="text-danger">*</span>
                                                    </slot>
                                                    <slot v-else-if="formData.allocation_type === 2">
                                                        {{ $t('seedsSeeds.farmer')}} <span class="text-danger">*</span>
                                                    </slot>
                                                    <slot v-else>
                                                        {{$t('seedsSeeds.applicant')}} <span class="text-danger">*</span>
                                                    </slot>
                                                </template>
                                                <b-form-select
                                                    plain
                                                    v-model="formData.off_dealer_farmer_comp_id"
                                                    :options="applicantList"
                                                    id="off_dealer_farmer_comp_id"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                                <template v-slot:first>
                                                    <!-- <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option> -->
                                                    <b-form-select-option :value="0">{{ applicantLoading ? 'Loading..' : $t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                                </b-form-select>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4" v-if="showOtherField">
                                        <ValidationProvider name="Other Destination" vid="other_designation">
                                            <b-form-group
                                                class="row"
                                                label-cols-sm="4"
                                                label-for="other_designation"
                                                slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('seedsSeeds.others') }}
                                            </template>
                                                <b-form-input
                                                id="other_designation"
                                                v-model="formData.other_designation"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                :placeholder="$t('seedsSeeds.enter_other')"
                                                ></b-form-input>
                                                <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <ValidationProvider name="Measurement Unit ID" vid="measurement_unit_id"  rules="required|min_value:1">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="4"
                                            label-for="measurement_unit_id"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{$t('seedsConfig.measurementUnit')}} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-select
                                                plain
                                                v-model="formData.measurement_unit_id"
                                                :options="measurementUnitList"
                                                id="measurement_unit_id"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                disabled
                                                >
                                                <template v-slot:first>
                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                </template>
                                            </b-form-select>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </div>
                                <template>
                                    <div style="font-size:15px; background-color: #005B5B;">
                                    <h5 class="text-white text-center mb-3">
                                        {{ $t('seedsSeeds.company_farmer_details_disburse') }}
                                    </h5>
                                    </div>
                                </template>
                                <div style="border:1px solid #4D4442; margin-bottom:20px; padding:10px; margin-top:10px">
                                    <b-overlay :show="loading">
                                        <div class="row">
                                            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                                <ValidationProvider name="Seed Class" vid="seed_class">
                                                    <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="seed_class"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('seedsConfig.seedClass')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="details.seed_class_id"
                                                        :options="seedClassList"
                                                        id="seed_class_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                                <ValidationProvider name="Crop Type" vid="crop_type">
                                                    <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="crop_type"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('seedsConfig.cropType')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="details.crop_type_id"
                                                        :options="cropTypeList"
                                                        id="crop_type_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                                <ValidationProvider name="Crop Name" vid="crop_name_id">
                                                    <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="crop_name_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('seedsConfig.cropName')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="details.crop_name_id"
                                                        :options="cropNameList"
                                                        id="crop_name_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                                <ValidationProvider name="variety" vid="variety_id">
                                                    <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="variety_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                        {{$t('seedsSeeds.varietyName')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="details.variety_id"
                                                        :options="varietyList"
                                                        id="variety_id"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                        <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                                <ValidationProvider name="Avaliable Quantity" vid="avaliable_qnty">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="avaliable_qnty"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('seedsSeeds.avaliable_qnty')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                        readonly
                                                        type="number"
                                                        id="avaliable_qnty"
                                                        v-model="details.avaliable_qnty"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                                <ValidationProvider name="Sales Quantity" vid="sales_qnty" rules="max_value:@avaliable_qnty">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="sales_qnty"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('seedsSeeds.sales_qnty')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                        type="number"
                                                        id="sales_qnty"
                                                        v-model="details.sales_qnty"
                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                <div class="text-right">
                                                    <button @click="addItem" class="btn btn-sm btn-primary mr-2" type="button"
                                                ><i class="fas fa-plus-circle m-0"></i> {{$t('dae_subsidy.add')}}</button>
                                                </div>
                                        </b-col>
                                        </div>
                                    </b-overlay>
                                </div>
                                <!-- -----------------Add More Start------------------- -->
                                    <b-table-simple striped bordered small class="mt-3">
                                        <b-thead>
                                        <tr>
                                            <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                            <b-th>{{ $t('seedsConfig.seedClass') }}</b-th>
                                            <b-th>{{ $t('seedsConfig.cropType') }}</b-th>
                                            <b-th>{{ $t('seedsConfig.cropName') }}</b-th>
                                            <b-th>{{ $t('seedsSeeds.varietyName') }}</b-th>
                                            <b-th>{{ $t('seedsSeeds.avaliable_qnty') }}</b-th>
                                            <b-th>{{ $t('seedsSeeds.sales_qnty') }}</b-th>
                                            <b-th class="text-center">{{ $t('seedsSeeds.unitPrice') }}</b-th>
                                            <b-th class="text-center">{{ $t('seedsSeeds.totalPrice') }}</b-th>
                                            <b-th>{{ $t('globalTrans.action') }}</b-th>
                                        </tr>
                                        </b-thead>
                                        <b-tbody>
                                        <template v-if="formData.farComdetails && formData.farComdetails.length">
                                            <b-tr v-for="(item, index) in formData.farComdetails" :key="index">
                                            <b-td  class="text-center">{{ index+1 }}</b-td>
                                            <b-td class="text-center">{{ currentLocale === 'en' ? item.seed_name : item.seed_name_bn }}</b-td>
                                            <b-td class="text-center">{{ currentLocale === 'en' ? item.crop_type : item.crop_type_bn }}</b-td>
                                            <b-td class="text-center">{{ currentLocale === 'en' ? item.crop_name : item.crop_name_bn }}</b-td>
                                            <b-td class="text-center">{{ currentLocale === 'en' ? item.variety_name : item.variety_name_bn }}</b-td>
                                            <b-td class="text-center">{{ $n(item.avaliable_qnty) }}</b-td>
                                            <b-td class="text-center">{{ $n(item.sales_qnty) }}</b-td>
                                            <b-td class="text-center">{{ $n(item.unit_price) }}</b-td>
                                            <b-td class="text-center">{{ $n(item.total_price) }}</b-td>
                                            <b-td class="text-center">
                                                <button @click="remove(index)" class="btn btn-sm btn-danger" type="button"><i class="fas fa-window-close m-0"></i></button>
                                            </b-td>
                                            </b-tr>
                                        </template>
                                        <template v-else>
                                            <b-tr>
                                            <b-td colspan="10" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                            </b-tr>
                                        </template>
                                        </b-tbody>
                                        <b-tfoot>
                                        </b-tfoot>
                                    </b-table-simple>
                                <!-- -----------------Add More End--------------------- -->
                                <div class="row">
                                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                        <div class="row">
                                            <div class="col-sm-3"></div>
                                            <div class="col text-right">
                                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                &nbsp;
                                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                                            </div>
                                        </div>
                                    </b-col>
                                </div>
                            </b-form>
                        </ValidationObserver>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { companyFarmerDisburseStore, companyFarmerInfoList, comFarDisburseVarietyUnitPrice, comFarDisburseVarietyAvailableQty } from '../../api/routes'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        this.formData.org_id = this.$store.state.dataFilters.orgId
        this.formData = Object.assign({}, this.formData, { fiscal_year_id: this.currentFiscalYearId })
        if (this.id) {
            const tmp = this.getformDataData()
            this.formData = tmp
        }
    },
    data () {
        return {
            loading: false,
            applicantLoading: false,
            showOtherField: false,
            instrumentSel: false,
            cropsSel: false,
            meteriatSel: true,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            formData: {
                fiscal_year_id: 0,
                production_season_id: 0,
                measurement_unit_id: 2,
                org_id: 0,
                division_id: 0,
                district_id: 0,
                upazilla_id: 0,
                office_id: this.$store.state.dataFilters.officeId,
                allocation_type: 0,
                off_dealer_farmer_comp_id: 0,
                zone_office_type_id: 0,
                other_designation: '',
                farComdetails: []
            },
            details: {
                seed_class_id: 0,
                crop_type_id: 0,
                crop_name_id: 0,
                variety_id: 0,
                avaliable_qnty: '',
                sales_qnty: ''
            },
            districtList: [],
            upazillaList: [],
            applicantList: [],
            cropNameList: [],
            zoneOfficeTypeList: [],
            officeList: [],
            varietyList: [],
            applyByList: [
                { value: 1, text: this.$t('seedsSeeds.institute') },
                { value: 2, text: this.$t('seedsSeeds.farmer') }
            ]
        }
    },
    watch: {
        'formData.division_id': function (newVal, oldVal) {
            this.districtList = this.getDistrictList(newVal)
        },
        'formData.district_id': function (newVal, oldVal) {
            this.upazillaList = this.getUpazilaList(newVal)
        },
        'formData.allocation_type': function (newVal, oldVal) {
            if (newVal === 1 || newVal === 2) {
                this.applicantList = this.getApplicantList(newVal)
            }
        },
        'formData.off_dealer_farmer_comp_id': function (newVal) {
             if (newVal === -1) {
            this.showOtherField = true
            } else {
                this.showOtherField = false
            }
        },
        'details.crop_type_id': function (newVal, oldVal) {
            this.cropNameList = this.getCropNameList(newVal)
        },
        'details.crop_name_id': function (newVal, oldVal) {
            this.varietyList = this.getVarietyList(newVal)
        },
        'details.variety_id': function (newVal, oldVal) {
            if (newVal) {
                this.getVarietyAvaialableQuantity()
            }
        },
        'formData.org_id': function (newVal, oldVal) {
            this.zoneOfficeTypeList = this.getZoneOfficeTypeList(newVal)
            this.formData.zone_office_type_id = this.$store.state.dataFilters.officeTypeId
        },
        'formData.zone_office_type_id': function (newVal, oldVal) {
            this.officeList = this.getZoneOfficeList(newVal)
        }
    },
    mounted () {
        core.index()
    },
    computed: {
        orgList: function () {
            const tmpData = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
            return tmpData.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text }
                }
            })
        },
        fiscalList: function () {
            const fiscalyearData = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
            return fiscalyearData.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { value: item.value, text: item.text_bn }
                } else {
                    return { value: item.value, text: item.text_en }
                }
            })
        },
        productionSeasonList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.productionSeasonList.filter(item => item.status === 1)
        },
        seedClassList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.seedClassList.filter(item => item.status === 1)
        },
        measurementUnitList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.unitList
        },
        cropTypeList: function () {
            return this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.status === 1)
        },
        currentLocale: function () {
            return this.$i18n.locale
        },
        divisionList: function () {
            return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
        },
        currentFiscalYearId: function () {
            return this.$store.state.SeedsFertilizer.currentFiscalYearId
        }
    },
    methods: {
        getformDataData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            return JSON.parse(JSON.stringify(tmpData))
        },
        async saveData () {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }
            result = await RestApi.postData(seedFertilizerServiceBaseUrl, companyFarmerDisburseStore, this.formData)
             loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            this.loading = false
            if (result.success) {
                this.$store.commit('mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                    title: this.$t('globalTrans.success'),
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })

                this.$bvModal.hide('modal-4')
            } else {
                this.$refs.form.setErrors(result.errors)
            }
        },
        async addItem () {
            if (this.details.seed_class_id && this.details.crop_type_id && this.details.crop_name_id && this.details.variety_id && this.details.avaliable_qnty && this.details.sales_qnty > 0) {
                const searchIteams = {
                    fiscal_year_id: this.formData.fiscal_year_id,
                    org_id: this.formData.org_id,
                    production_season_id: this.formData.production_season_id,
                    seed_class_id: this.details.seed_class_id,
                    crop_type_id: this.details.crop_type_id,
                    crop_name_id: this.details.crop_name_id,
                    measurement_unit_id: this.formData.measurement_unit_id,
                    allocation_type: this.formData.allocation_type,
                    variety_id: this.details.variety_id
                }
                const result = await RestApi.getData(seedFertilizerServiceBaseUrl, comFarDisburseVarietyUnitPrice, searchIteams)
                if (result.success) {
                    const seedClass = this.$store.state.SeedsFertilizer.commonObj.seedClassList.filter(item => item.value === this.details.seed_class_id)
                    const cropType = this.$store.state.SeedsFertilizer.commonObj.CropTypeList.filter(item => item.value === this.details.crop_type_id)
                    const cropName = this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.value === this.details.crop_name_id)
                    const varietyName = this.$store.state.SeedsFertilizer.commonObj.VarietyList.filter(item => item.value === this.details.variety_id)
                    const obj = {
                        seed_class_id: this.details.seed_class_id,
                        seed_name: seedClass[0]?.text_en,
                        seed_name_bn: seedClass[0]?.text_bn,
                        crop_type_id: this.details.crop_type_id,
                        crop_type: cropType[0]?.text_en,
                        crop_type_bn: cropType[0]?.text_bn,
                        crop_name_id: this.details.crop_name_id,
                        crop_name: cropName[0]?.text,
                        crop_name_bn: cropName[0]?.text,
                        variety_id: this.details.variety_id,
                        variety_name: varietyName[0]?.text,
                        variety_name_bn: varietyName[0]?.text,
                        avaliable_qnty: this.details.avaliable_qnty,
                        sales_qnty: this.details.sales_qnty,
                        unit_price: parseFloat(result.data),
                        total_price: parseInt(this.details.sales_qnty * result.data)
                    }
                    const objExist = this.formData.farComdetails.find(detail => detail.seed_class_id === obj.seed_class_id && detail.crop_type_id === obj.crop_type_id && detail.crop_name_id === obj.crop_name_id && detail.variety_id === obj.variety_id)
                        if (typeof objExist === 'undefined') {
                            this.formData.farComdetails.push(obj)
                        } else {
                            this.$toast.error({
                                title: 'Data has already been added',
                                color: '#D6E09B'
                            })
                        }
                } else {
                     this.$toast.error({
                        message: result.message,
                        color: '#D6E09B'
                    })
                }
            }
            this.details = {
                seed_class_id: 0,
                crop_type_id: 0,
                crop_name_id: 0,
                variety_id: 0,
                avaliable_qnty: '',
                sales_qnty: ''
            }
            this.$refs.form.reset()
        },
        remove (key) {
            this.formData.farComdetails.splice(key, 1)
        },
        getDistrictList (divisionId) {
            const list = this.$store.state.commonObj.districtList.filter(item => item.status === 0 && item.division_id === divisionId)
                if (divisionId) {
                    return list.filter(district => district.division_id === divisionId)
                }
                return list
        },
        getUpazilaList (districtId = null) {
            const upazillaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

            if (districtId) {
                return upazillaList.filter(upazila => upazila.district_id === districtId)
            }

            return upazillaList
        },
        getApplicantList (appType) {
            switch (appType) {
                case 1:
                    this.applicantLoading = true
                    RestApi.getData(seedFertilizerServiceBaseUrl, companyFarmerInfoList).then(response => {
                        if (response.success) {
                            const data = response.data.filter(doc => doc.category_id === 1)
                            this.applicantList = data.map(item => {
                                if (this.$i18n.locale === 'en') {
                                return { value: item.id, text: item.company_name }
                                } else {
                                return { value: item.id, text: item.company_name_bn }
                                }
                            })
                            this.applicantList.push({
                             value: -1, text: this.$t('globalTrans.other')
                            })
                        }
                    })
                    this.applicantLoading = true
                break
                case 2:
                    this.applicantLoading = true
                    RestApi.getData(seedFertilizerServiceBaseUrl, companyFarmerInfoList).then(response => {
                        if (response.success) {
                            const data = response.data.filter(doc => doc.category_id === 2)
                            this.applicantList = data.map(item => {
                                if (this.$i18n.locale === 'en') {
                                return { value: item.id, text: item.farmer_name }
                                } else {
                                return { value: item.id, text: item.farmer_name_bn }
                                }
                            })
                            this.applicantList.push({
                                value: -1, text: this.$t('globalTrans.other')
                            })
                        }
                    })
                    this.applicantLoading = true
                break
                default:
                break
            }
        },
        getCropNameList (croptypeID) {
            const cropNameList = this.$store.state.SeedsFertilizer.commonObj.CropNameList.filter(item => item.status === 1 && item.crop_type_id === croptypeID)
            return cropNameList
        },
        getVarietyList (cropNameId) {
            const varietyList = this.$store.state.SeedsFertilizer.commonObj.VarietyList.filter(item => item.status === 1 && item.crop_name_id === cropNameId)
            return varietyList
        },
        async getVarietyAvaialableQuantity () {
            const searchIteams = {
                org_id: this.formData.org_id,
                fiscal_year_id: this.formData.fiscal_year_id,
                production_season_id: this.formData.production_season_id,
                division_id: this.formData.division_id,
                district_id: this.formData.district_id,
                upazilla_id: this.formData.upazilla_id,
                measurement_unit_id: this.formData.measurement_unit_id,
                to_sales_office_id: this.formData.office_id,
                seed_class_id: this.details.seed_class_id,
                crop_type_id: this.details.crop_type_id,
                crop_name_id: this.details.crop_name_id,
                variety_id: this.details.variety_id
            }
            this.loading = true
            const result = await RestApi.getData(seedFertilizerServiceBaseUrl, comFarDisburseVarietyAvailableQty, searchIteams)
            if (result.success) {
                this.details.avaliable_qnty = result.data
            } else {
                this.details.avaliable_qnty = ''
                this.$toast.error({
                        title: 'error',
                        message: result.message,
                        color: '#D6E09B'
                    })
            }
            this.loading = false
        },
        getZoneOfficeTypeList (orgId = null) {
            const officeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
                if (orgId) {
                    return officeTypeList.filter(office => office.org_id === orgId).map(obj => {
                        if (this.$i18n.locale === 'bn') {
                            return { value: obj.value, text: obj.text_bn }
                        } else {
                            return { value: obj.value, text: obj.text }
                        }
                    })
                }
                return officeTypeList
        },
        getZoneOfficeList (officeTypeId) {
            const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
                if (officeTypeId) {
                    return officeList.filter(item => item.office_type_id === officeTypeId)
                }
            return officeList
        }
    }
}
</script>

<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-overlay :show="loading">
                      <div id="print-area">
                        <template>
                            <b-row>
                              <b-col>
                                  <list-report-head :base-url="seedFertilizerServiceBaseUrl" :uri="reportHeadingList" :org-id="seedOrgId">
                                      {{ getAllocationType(formData.allocation_type) }} {{ $t('seedsSeeds.wise_disburse_details') }}
                                  </list-report-head>
                              </b-col>
                            </b-row>
                        </template>
                        <b-table-simple borderless>
                          <b-tbody>
                            <b-tr>
                              <b-td width="50%">
                                <slot v-if="formData.other_designation">
                                  <h4>{{ formData.other_designation }}</h4>
                                </slot>
                                <slot v-else>
                                  <slot v-if="companyFarmerInfo.company_name">
                                    <h4>{{ currentLocale === 'en' ? companyFarmerInfo.company_name : companyFarmerInfo.company_name_bn }}</h4>
                                    <p>
                                      <span><strong>{{ $t('globalTrans.address') }}</strong>: {{ currentLocale === 'en' ? companyFarmerInfo.address : companyFarmerInfo.address_bn }}</span> <br>
                                      <span><strong>{{ $t('globalTrans.mobile') }}</strong>: {{ companyFarmerInfo.contact_person_mobile }}</span> <br>
                                      <span><strong>{{ $t('globalTrans.email') }}</strong>: {{ companyFarmerInfo.contact_person_email }}</span>
                                    </p>
                                  </slot>
                                  <slot v-else>
                                    <h4>{{ currentLocale === 'en' ? companyFarmerInfo.farmer_name : companyFarmerInfo.farmer_name_bn }}</h4>
                                    <p>
                                      <span><strong>{{ $t('globalTrans.division') }}</strong>: {{ currentLocale === 'en' ? companyFarmerInfo.division_name : companyFarmerInfo.division_name_bn }}</span> <br>
                                      <span><strong>{{ $t('globalTrans.district') }}</strong>: {{ currentLocale === 'en' ? companyFarmerInfo.district_name : companyFarmerInfo.district_name_bn }}</span> <br>
                                      <span><strong>{{ $t('globalTrans.upazila') }}</strong>: {{ currentLocale === 'en' ? companyFarmerInfo.upazila_name : companyFarmerInfo.upazila_name_bn }}</span>
                                    </p>
                                  </slot>
                                </slot>
                                <div class="bill-info mt-4">
                                   <h4>{{ $t('seedsSeeds.bill_to') }}</h4>
                                  <p>
                                    <span><strong>{{ $t('seedsSeeds.office') }}</strong>: {{ (this.$i18n.locale=='bn')? formData.office_name_bn : formData.office_name }}</span> <br>
                                    <span><strong>{{ $t('globalTrans.division') }}</strong>: {{ (this.$i18n.locale=='bn')? formData.division_name_bn : formData.division_name }} </span> <br>
                                    <span><strong>{{ $t('globalTrans.district') }}</strong>: {{ (this.$i18n.locale=='bn')? formData.district_name_bn : formData.district_name }}</span><br>
                                    <span><strong>{{ $t('org_pro_upazilla.upazilla') }}</strong>: {{ (this.$i18n.locale=='bn')? formData.upazilla_name_bn : formData.upazilla_name }}</span>
                                  </p>
                                </div>
                              </b-td>
                              <b-td class="text-right" width="50%" align="right">
                                <h3 class="text-uppercase">{{ $t('seedsSeeds.invoice')}}</h3>
                                <div class="mt-2 font-weight-bold">
                                  <p>
                                    <span class="text-uppercase">{{ $t('seedsSeeds.invoice_id')}} - {{ $n(formData.invoice_id, { useGrouping:false }) }}</span><br>
                                    <span class="text-uppercase">{{ $t('globalTrans.date') }}: {{ formData.created_at | dateFormat }}</span><br>
                                  </p>
                                </div>
                              </b-td>
                            </b-tr>
                          </b-tbody>
                        </b-table-simple>
                        <p>{{ '*  ' }}{{ $t('seedsSeeds.submit_msg') }}</p>
                        <!-- <b-table-simple striped bordered small>
                          <b-tr>
                            <b-th>{{ $t('dae_grant_allocation_distribution.fiscal_year') }}</b-th>
                            <b-td>{{ (this.$i18n.locale=='bn')? formData.fiscal_year_bn : formData.fiscal_year }}</b-td>
                            <b-th>{{ $t('seedsConfig.productionSeason') }}</b-th>
                            <b-td>{{ (this.$i18n.locale=='bn')? formData.season_name_bn : formData.season_name }}</b-td>
                          </b-tr>
                          <b-tr>
                            <b-th>{{ $t('seedsConfig.measurementUnit') }}</b-th>
                            <b-td colspan="3">{{ (this.$i18n.locale=='bn')? formData.unit_name_bn : formData.unit_name }}</b-td>
                          </b-tr>
                        </b-table-simple> -->
                        <h4>{{ $t('seedsSeeds.disburse_info') }}</h4>
                        <b-table-simple striped bordered small class="mt-2">
                            <b-tr>
                                <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                                <b-th>{{ $t('seedsConfig.seedClass') }}</b-th>
                                <b-th>{{ $t('seedsConfig.cropType') }}</b-th>
                                <b-th>{{ $t('seedsConfig.cropName') }}</b-th>
                                <b-th>{{ $t('seedsSeeds.varietyName') }}</b-th>
                                <b-th class="text-right">{{ $t('seedsSeeds.avaliable_qnty') }}</b-th>
                                <b-th class="text-right">{{ $t('seedsSeeds.sales_qnty') }}</b-th>
                                <b-th class="text-right">{{ $t('seedsSeeds.unitPrice') }}</b-th>
                                <b-th class="text-right">{{ $t('seedsSeeds.totalPrice') }}</b-th>
                            </b-tr>
                            <b-tr v-for="(info,index) in formDataDetails" :key="index">
                                <b-td>{{ $n(index+1) }}</b-td>
                                <b-td>{{ ($i18n.locale === 'bn') ? info.class_name_bn : info.class_name }}</b-td>
                                <b-td>{{ ($i18n.locale === 'bn') ? info.crop_type_bn : info.crop_type }}</b-td>
                                <b-td>{{ ($i18n.locale === 'bn') ? info.crop_name_bn : info.crop_name }}</b-td>
                                <b-td>{{ ($i18n.locale === 'bn') ? info.variety_name_bn : info.variety_name }}</b-td>
                                <b-td class="text-right">{{ $n(info.avaliable_qnty, { useGrouping: false } ) }} {{ ($i18n.locale=='bn')? formData.unit_name_bn : formData.unit_name }}</b-td>
                                <b-td class="text-right">{{ $n(info.sales_qnty, { useGrouping: false } ) }} {{ $t('seedsSeeds.kg') }}</b-td>
                                <b-td class="text-right">{{ $n(info.unit_price, { useGrouping: false } ) }}  {{ $t('seedsSeeds.tk') }}</b-td>
                                <b-td class="text-right">{{ $n(info.total_price, { useGrouping: false } ) }}  {{ $t('seedsSeeds.tk') }}</b-td>
                            </b-tr>
                          <b-tr>
                            <th colspan="8" class="text-right">{{ $t('globalTrans.total') }}</th>
                            <th class="text-right">{{ $n(totalPrice) }} {{ $t('seedsSeeds.tk') }}</th>
                          </b-tr>
                        </b-table-simple>
                        <h4 class="mt-4">{{ $t('seedsSeeds.thank_business') }}</h4>
                      </div>
                      <div class="row mt-4">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                          <b-button type="button" variant="primary" class="mr-2" @click="printID"><i class="fas fa-print"></i> {{ $t('globalTrans.print') }}</b-button>
                          &nbsp;
                          <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-5')">{{ $t('globalTrans.cancel') }}</b-button>
                        </div>
                      </div>
                    </b-overlay>
                  </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadSeed.vue'
import { reportHeadingList } from '../../api/routes'
export default {
  components: {
    ListReportHead
  },
  name: 'FormLayout',
  props: ['item'],
  created () {
    this.formData = this.item
    this.formDataDetails = this.getRelatinalData(this.item.details)
    let totalPrice = 0
    this.formDataDetails.forEach(item => {
      totalPrice += item.total_price
    })
    this.totalPrice = totalPrice
    this.getCompanyFarmerInfo()
    this.seedOrgId = 1
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.seedOrgId = this.$store.state.Auth.authUser.org_id
    }
  },
  data () {
    return {
      seedOrgId: 0,
      formData: {},
      formDataDetails: [],
      totalPrice: '',
      companyFarmerInfo: {},
      loading: false,
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    },
    listData () {
      const objectData = this.item
      return objectData.filter(data => data.id === this.item.id)
    }
  },
  methods: {
    getRelatinalData (data) {
      const seedClassList = this.$store.state.SeedsFertilizer.commonObj.seedClassList
      const cropTypeList = this.$store.state.SeedsFertilizer.commonObj.CropTypeList
      const cropNameList = this.$store.state.SeedsFertilizer.commonObj.CropNameList
      const varietyNameList = this.$store.state.SeedsFertilizer.commonObj.VarietyList
      const listData = data.map(item => {
        const seedClassObj = seedClassList.find(seed => seed.value === item.seed_class_id)
        const cropTypeObj = cropTypeList.find(cropType => cropType.value === item.crop_type_id)
        const cropNameObj = cropNameList.find(cropName => cropName.value === item.crop_name_id)
        const varietyObj = varietyNameList.find(variety => variety.value === item.variety_id)
        const seedData = {
          class_name: seedClassObj !== undefined ? seedClassObj.text_en : '',
          class_name_bn: seedClassObj !== undefined ? seedClassObj.text_bn : ''
        }
        const cropTypeData = {
          crop_type: cropTypeObj !== undefined ? cropTypeObj.text_en : '',
          crop_type_bn: cropTypeObj !== undefined ? cropTypeObj.text_bn : ''
        }
        const cropNameData = {
          crop_name: cropNameObj !== undefined ? cropNameObj.text_en : '',
          crop_name_bn: cropNameObj !== undefined ? cropNameObj.text_bn : ''
        }
         const vaeirtyData = {
          variety_name: varietyObj !== undefined ? varietyObj.text_en : '',
          variety_name_bn: varietyObj !== undefined ? varietyObj.text_bn : ''
        }
        return Object.assign({}, item, seedData, cropTypeData, cropNameData, vaeirtyData)
      })
      return listData
    },
    async getCompanyFarmerInfo () {
      this.loading = true
      const result = await RestApi.getData(seedFertilizerServiceBaseUrl, 'seeds/config/company-farmers/show/' + this.formData.off_dealer_farmer_comp_id)
      if (result.success) {
        const data = result.data
        const division = this.$store.state.commonObj.divisionList.find(item => item.value === parseInt(data.division_id))
        const district = this.$store.state.commonObj.districtList.find(item => item.value === parseInt(data.district_id))
        const upazila = this.$store.state.commonObj.upazilaList.find(item => item.value === parseInt(data.upazilla_id))
        this.companyFarmerInfo = result.data
        this.companyFarmerInfo.division_name = division?.text_en
        this.companyFarmerInfo.division_name_bn = division?.text_bn
        this.companyFarmerInfo.district_name = district?.text_en
        this.companyFarmerInfo.district_name_bn = district?.text_bn
        this.companyFarmerInfo.upazila_name = upazila?.text_en
        this.companyFarmerInfo.upazila_name_bn = upazila?.text_bn
      } else {
        this.companyFarmerInfo = {}
      }
      this.loading = false
    },
    getAllocationType (type) {
      if (type === 1) {
        return this.$i18n.locale === 'bn' ? 'ইনস্টিটিউট' : 'Institute'
      } else if (type === 2) {
        return this.$i18n.locale === 'bn' ? 'কৃষক' : 'Farmer'
      }
    },
    printID () {
      // Get HTML to print from element
      const prtHtml = document.getElementById('print-area').innerHTML

      // Open the print window
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0')

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
         <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css" rel="stylesheet" media="print">
         <style>
           @media print {
              .text-center { text-align: center; }
              .text-right { text-align: right !important; }
              .row::after {
                  clear: both;
              }
              .row::before {
                  display: table;
                  content: " ";
              }
              .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6,
            .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-md-4, col-md-8 {
                 float: left;
            }

            .col-lg-12 {
                 width: 100%;
            }

            .col-lg-11 {
                 width: 91.66666666666666%;
            }

            .col-lg-10 {
                 width: 83.33333333333334%;
            }

            .col-lg-9 {
                  width: 75%;
            }

            .col-lg-8 {
                  width: 66.66666666666666%;
            }

             .col-lg-7 {
                  width: 58.333333333333336%;
             }

             .col-lg-6 {
                  width: 50%;
             }

             .col-lg-5 {
                  width: 41.66666666666667%;
             }

             .col-lg-4 {
                  width: 33.33333333333333%;
             }

             .col-lg-3 {
                  width: 25%;
             }

             .col-lg-2 {
                    width: 16.666666666666664%;
             }

             .col-lg-1 {
                    width: 8.333333333333332%;
              }
           }
         </style>
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`)

      WinPrint.document.close()
      setTimeout(() => {
        WinPrint.focus()
        WinPrint.print()
        WinPrint.close()
      }, 1000)
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
